import Head from "next/head"
import Script from "next/script"

import "styles.css"
import "fonts.js"

import PreviewAlert from "components/PreviewAlert"
import Header from "components/Header"
import Footer from "components/Footer"
import GlobalForm from "components/GlobalForm"

import useStoryblok from "storyblok/useStoryblok"
import { resolve_relations } from "storyblok/resolveRelations"

function MyApp({ Component, pageProps: { preview, story, globals, ...remainingProps } }) {
  story = useStoryblok({ story, resolve_relations })

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {preview && <PreviewAlert />}
      <Header header={globals?.header} phoneNumbers={globals?.phoneNumbers} />
      <Component story={story} socialMedia={globals?.socialMedia} {...remainingProps} />
      {globals?.globalForm ? <GlobalForm {...globals.globalForm} /> : null}
      <Footer
        footerMenu={globals?.footerMenu}
        footerContent={globals?.footerContent}
        phoneNumbers={globals?.phoneNumbers}
        socialMedia={globals?.socialMedia}
      />
      <Script type="application/ld+json" id="organization-schema">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.husbandandwifelawteam.com",
          "sameAs": [${Object?.values(globals?.socialMedia).map(
            (link, idx) => `"${link}"${Object?.values(globals?.socialMedia).length - 1 < idx ? `, ` : ``}`,
          )}],
          "name" : "The Husband & Wife Law Team",
          "email": "",
          "telephone": "${globals?.phoneNumbers?.Main}"
        }`}
      </Script>
    </>
  )
}

export default MyApp
