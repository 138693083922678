import { useRouter } from "next/router"
import { useEffect, useState, useRef } from "react"

const localStorageURL = () => {
  if (typeof window !== "undefined") {
    const storedURL = JSON.parse(localStorage.getItem("URL_with_UTM_params"))
    if (typeof storedURL === "string") {
      return new URL(storedURL)
    }
  }

  return
}

export default function JotFormEmbed({ formURL, autoFocus, heightOffset = 30, initialHeight = 640, formID, ...rest }) {
  formID = formURL?.split("/")[formURL?.split("/").length - 1]
  const iframeRef = useRef(null)
  const [componentStyles, setComponentStyles] = useState({
    height: initialHeight,
    overflow: "hidden",
    border: 0,
    width: "100%",
  })

  const [hasMounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    const handleMessages = (e) => {
      if (!e.origin.indexOf("jotform")) {
        return
      }

      // track form submission
      if (e.data?.action === "submission-completed") {
        const dataLayer = window.dataLayer || []
        dataLayer.push({ event: "form_submission", form_id: formID })
        return
      }

      // From now on we only handle style related messages
      if (typeof e.data !== "string") {
        return
      }
      const [method, value, targetForm] = e.data.split(":")
      // eslint-disable-next-line
      if (formID && targetForm && targetForm !== formID) {
        // If you want to use multiple form via embed you need to use formID
        return
      }

      switch (method) {
        case "scrollIntoView" && autoFocus:
          if (typeof iframeRef.current.scrollIntoView === "function") {
            iframeRef.current.scrollIntoView()
          }
          break
        case "setHeight":
          setComponentStyles({ ...componentStyles, height: parseInt(value, 10) + heightOffset })
          break
        case "setMinHeight":
          setComponentStyles({ ...componentStyles, minHeight: parseInt(value, 10) + heightOffset })
          break
        case "reloadPage":
          try {
            iframeRef.current.contentWindow.location.reload()
          } catch (e) {
            console.info("failed to reload", e)
          }
          break
        default:
          break
      }
    }

    window.addEventListener("message", handleMessages, true)

    return () => {
      window.removeEventListener("message", handleMessages, true)
    }
  }, [autoFocus, componentStyles, formID, heightOffset, formURL, initialHeight])

  const { query } = useRouter()
  const [URLWithParentParams, setURL] = useState()
  const [storedURL, setStoredURL] = useState()

  useEffect(() => {
    const url = new URL(formURL)
    const params = new URLSearchParams([...[...url.searchParams.entries()], ...Object.entries(query)])
    const urlWithParentParams = new URL(`${url.origin}${url.pathname}?${params}`)
    setURL(urlWithParentParams)

    if (urlWithParentParams.toString().includes("utm")) {
      localStorage.setItem("URL_with_UTM_params", JSON.stringify(urlWithParentParams))
    }
  }, [formURL, query])

  useEffect(() => {
    setStoredURL(localStorageURL())
  }, [])

  return hasMounted ? (
    <iframe
      ref={iframeRef}
      src={storedURL ? storedURL : URLWithParentParams}
      title="JotForm Form"
      style={componentStyles}
      allowFullScreen
      allow="geolocation; microphone; camera"
      {...rest}
    />
  ) : null
}
